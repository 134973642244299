import React from 'react';
import { Col, Row } from 'react-bootstrap';
import EventCardLoading from './EventCardLoading/EventCardLoading';
import OldCardEventLoading from './OldEventCardLoading/OldCardEventLoading';

export default function EventsScreenLoading() {
	return (
		<Row>
			<Col xs={12} md={6} lg={8} xl={9}>
				<Row className='mb-3'>
					<h2 className='c-title'>Próximos eventos</h2>
				</Row>
				<Row className='gap-4 justify-content-center'>
					{Array.from({ length: 3 }).map((_, index) => (
						<EventCardLoading key={index} />
					))}
				</Row>
			</Col>
			<Col xs={12} md={6} lg={4} xl={3}>
				<Row className='mb-3 justify-content-center'>
					<h2 className='c-title text-center'>
						Otros eventos pasados
					</h2>
				</Row>
				<Row
					className='justify-content-center'
					style={{ padding: '0 35px' }}
				>
					<OldCardEventLoading />
				</Row>
			</Col>
		</Row>
	);
}
